import Link from 'next/link';

import { Button } from '../button/Button';

export const SimpleHero = () => {
  return (
    <div className="bg-indigo-700 py-16 px-24 text-center">
      <div className="pb-5 text-xl text-white md:text-4xl lg:text-6xl">
        Build and launch your <br />
        treatment today.
      </div>
      <div>
        <Link href="/get-started">
          <Button>Get Started</Button>
        </Link>
      </div>
    </div>
  );
};
