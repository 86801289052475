import { BsClipboardCheck } from 'react-icons/bs';
import { FaRegHeart } from 'react-icons/fa';
import { FaStaffSnake, FaUserDoctor } from 'react-icons/fa6';
import { IoIosPhonePortrait } from 'react-icons/io';
import { MdLockOutline } from 'react-icons/md';

import bottomImage from '../../../public/assets/images/contentInChair.jpg';
import topImage from '../../../public/assets/images/sittingWithPhone.png';
import { FadeInImage } from '../image/FadeInImage';

export const ThirdOverview = () => {
  return (
    <div className="text-center text-black lg:px-24">
      <div>
        <div className="pt-10 text-2xl font-bold sm:text-3xl md:text-5xl">A Modern Approach to Medicine</div>
        <div className="mx-auto px-10 pt-5 sm:px-40 md:px-52 2xl:w-8/12">
          Many chronic conditions are best treated through education, training, and patient support. Outcomes are driven
          by what happens between doctors visits. Whether through Virtual Specialty Care or Digital Therapeutics,
          digitally delivered treatments are radically increasing access to effective care.
        </div>
        <div className="mx-auto px-10 pt-5 sm:px-40 md:px-52 2xl:w-8/12">
          We provide treatment developers with a Treatment Development Platform to build and launch digital treatments.
        </div>
      </div>
      <div className="justify-center text-left sm:flex md:px-10">
        <div className="max-w-xl px-14 pt-10 sm:pt-24 md:px-14">
          <FadeInImage className="animate-fade rounded-3xl" src={topImage} alt={''} />
        </div>
        <div className="max-w-xl pr-2 md:pt-16 xl:pt-24">
          <div className="flex py-5">
            <div className="px-4 text-5xl text-purple-700">
              <IoIosPhonePortrait />
            </div>
            <div className="">
              <div className="font-bold">Software, Not Code</div>
              <div>
                We empower clinicians to create elegant digital treatments without writing code or hiring developers.
              </div>
            </div>
          </div>
          <div className="flex py-5">
            <div className="px-4 text-5xl text-purple-700">
              <FaRegHeart />
            </div>
            <div>
              <div className="font-bold">Experienced Enagement</div>
              <div>
                Our platform provides a an engaging delivery mechanism for digital treatments, informed by years of
                experience in digital medicine.
              </div>
            </div>
          </div>
          <div className="flex py-5">
            <div className="px-4 text-5xl text-purple-700">
              <BsClipboardCheck />
            </div>
            <div>
              <div className="font-bold">Clinical Validation</div>
              <div>
                We include analytics tools to validate the clinical efficacy of digital treatments and to track patient
                progression. We include standard measures like PHQ-9.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-center pr-2 text-left sm:flex md:px-10">
        <div className="max-w-xl md:pt-24 xl:pt-40">
          <div className="flex py-5">
            <div className="px-4 text-5xl text-purple-700">
              <MdLockOutline />
            </div>
            <div>
              <div className="font-bold">Private + Secure</div>
              <div>
                Our platform is built with data security at the core. We have rigorous guidelines to keep your data and
                information protected, and your organization compliant.
              </div>
            </div>
          </div>
          <div className="flex py-5">
            <div className="px-4 text-5xl text-purple-700">
              <FaStaffSnake />
            </div>
            <div>
              <div className="font-bold">Regulatory Clearance</div>
              <div>
                Our platform is built not only to comply, but to reduce the administrative burden of seeking regulatory
                clearance.
              </div>
            </div>
          </div>
          <div className="flex py-5">
            <div className="px-4 text-5xl text-purple-700">
              <FaUserDoctor />
            </div>
            <div>
              <div className="font-bold">Provider Integration</div>
              <div>
                We seek to strengthen the patient-provider relationship by integrating with existing EHRs and provider
                workflows, during clinical trial and post-launch.
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-xl px-14 py-10 sm:pt-36 md:px-14">
          <FadeInImage className="rounded-3xl" src={bottomImage} alt={''} />
        </div>
      </div>
    </div>
  );
};
