import type { StaticImageData } from 'next/image';
import Image from 'next/image';

import { FadeIn } from '../background/FadeIn';

interface FadeInImageProps {
  src: string | StaticImageData;
  alt: string;
  width?: number;
  height?: number;
  className?: string;
}

export const FadeInImage: React.FC<FadeInImageProps> = (props: FadeInImageProps) => {
  return (
    <FadeIn>
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <Image {...props} />
    </FadeIn>
  );
};
