'use client';

import { Featured } from '@/components/featured/Featured';
import { SimpleHero } from '@/components/hero/SimpleHero';
import { LandingHeader } from '@/components/landingHeader/LandingHeader';
import { Meta } from '@/components/layout/Meta';
import { PlatformOverview } from '@/components/platformOverview/PlatformOverview';
import { ThirdOverview } from '@/components/platformOverview/ThirdOverview';

import { AppConfig } from '../config/AppConfig';
import { Footer } from './Footer';
import { Navbar } from './Navbar';

const Base = () => {
  return (
    <div className="text-gray-600 antialiased">
      <Meta title={AppConfig.title} description={AppConfig.description} />
      <Navbar />
      <LandingHeader />
      <ThirdOverview />
      <PlatformOverview />
      <Featured button={false} />
      <SimpleHero />
      {/* <SecondaryOverview />
      <TableChecklist /> */}
      <Footer />
    </div>
  );
};

export { Base };
