// import video from '../../../public/assets/images/smilingPhone.mp4';
import Link from 'next/link';

import { FadeIn } from '../background/FadeIn';
import { Button } from '../button/Button';
import { HeroOneButton } from '../hero/HeroOneButton';

export const LandingHeader = () => {
  return (
    <div
      id="landingHeader"
      style={{
        backgroundImage: `url('/assets/images/phoneHero.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        minHeight: '75vh',
      }}
      // Adjust padding and height based on screen size
      className="relative overflow-hidden bg-black bg-opacity-20 bg-blend-multiply"
    >
      <video
        autoPlay={true}
        muted
        loop
        className="absolute top-1/2 left-1/2 z-0 h-auto min-h-full w-auto min-w-full max-w-none -translate-x-1/2 -translate-y-1/2"
        src={'/assets/videos/phoneHero.mp4'}
        playsInline={true}
      >
        Your browser does not support the video tag.
      </video>
      <div className="relative z-10 h-full min-h-[75vh] bg-black bg-opacity-30">
        <FadeIn threshold={0.3}>
          <HeroOneButton
            title={'Powering the next generation of digitally delivered treatments'}
            // Adjust title text size based on screen size
            titleClassName="text-white text-3xl lg:text-8xl xl:text-9xl 2xl:pl-72 2xl:pb-10 xl:pl-24 pb-5 px-2 pt-52"
            description={
              'Welcome to the first open platform built for clinicians to develop clinically validated digital treatments.'
            }
            // Adjust description text size based on screen size
            descriptionClassName="text-white text-base text-xl 2xl:text-2xl 2xl:pl-72 xl:pl-24 pt-7 pb-0 px-2 mx-auto lg:mx-0 2xl:max-w-full"
            button={
              <div className="flex w-full justify-around space-x-0 px-2 pt-0 pb-5 md:justify-center md:space-x-4 lg:justify-start xl:space-x-4 xl:pl-24 2xl:pl-72">
                <Link href="/get-started">
                  <Button>Get Started</Button>
                </Link>
              </div>
            }
            image={{
              src: '',
              alt: '',
            }}
          />
        </FadeIn>
      </div>
    </div>
  );
};
