import {
  HiOutlineCalendar,
  HiOutlineChatAlt2,
  HiOutlineCheckCircle,
  HiOutlineClipboardCheck,
  HiOutlineFilm,
  HiOutlineIdentification,
  HiOutlineMusicNote,
  HiOutlinePresentationChartLine,
  HiOutlineScale,
  HiOutlineShieldCheck,
  HiOutlineSparkles,
  HiOutlineStar,
  HiOutlineUsers,
  HiOutlineVideoCamera,
  HiPencilAlt,
} from 'react-icons/hi';

import { FadeIn } from '../background/FadeIn';

const overview = [
  {
    name: 'Intake & Assessment',
    icon: HiOutlineClipboardCheck,
  },
  {
    name: 'Audio Content',
    icon: HiOutlineMusicNote,
  },
  {
    name: 'Video Content',
    icon: HiOutlineFilm,
  },
  {
    name: 'Journaling',
    icon: HiPencilAlt,
  },
  {
    name: 'Quality Management System',
    icon: HiOutlineShieldCheck,
  },
  {
    name: 'Verification Testing Automation',
    icon: HiOutlineCheckCircle,
  },
  {
    name: 'Analyst Data Portal',
    icon: HiOutlinePresentationChartLine,
  },
  {
    name: 'Scheduled Assessments',
    icon: HiOutlineCalendar,
  },
  {
    name: 'White Labeling',
    icon: HiOutlineStar,
  },
  {
    name: 'Provider Management',
    icon: HiOutlineIdentification,
  },
  {
    name: 'Video Conferencing',
    icon: HiOutlineVideoCamera,
  },
  {
    name: 'Patient Management',
    icon: HiOutlineUsers,
  },
  {
    name: 'Messaging',
    icon: HiOutlineChatAlt2,
  },
  {
    name: 'Engagement Automation',
    icon: HiOutlineSparkles,
  },
  {
    name: 'A/B Testing',
    icon: HiOutlineScale,
  },
];
export const PlatformOverview = () => {
  return (
    <FadeIn id="overview" className="flex w-full items-center justify-center p-5 md:py-10">
      <div className="mx-auto w-full max-w-5xl rounded-2xl bg-gradient-to-br from-blue-500 via-purple-500 to-pink-500">
        <div className="h-full w-full bg-white bg-opacity-80 p-10">
          <div className="mb-10 text-center">
            <h2 className="mb-4 text-3xl font-semibold text-gray-800">
              A comprehensive platform to build, validate, and deliver digital treatments.
            </h2>
            <div className="flex items-center justify-center space-x-2 text-purple-600">
              <span className="font-medium">Features and Support</span>
            </div>
          </div>
          <div className="grid grid-cols-2 justify-between gap-4 text-sm md:grid-cols-3 md:pl-12 md:text-base">
            {overview.map((item, index) => (
              <div key={index} className="flex items-center space-x-2">
                <div className="rounded bg-white p-2">
                  <item.icon className="text-purple-500" size="24" />
                </div>
                <span className="text-gray-700">{item.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </FadeIn>
  );
};
