import { useRouter } from 'next/router';

import { Base } from '../template/Base';

export default function Home() {
  const router = useRouter();
  // if (typeof window !== 'undefined') {
  //   if (router.asPath !== '/') {
  //     // Handle dyanmic routes by triggering the router on the curent path
  //     router.replace(router.asPath);
  //   } else {
  //     router.replace(routes.subscriptions);
  //   }
  // }

  if (router.asPath.split('#')[0] !== '/') {
    // Handle dyanmic routes by triggering the router on the curent path
    router.replace(router.asPath);
  }

  return <Base />;
}
